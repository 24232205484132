.account{
  div, header, ul, li, .tabs-title>a{
    &:focus, &:hover, &:active{
      outline:none;
    }
  }
  //.form-cont{
  //  input[type=submit].button.submit, button.submit{
  //    max-width:rem-calc(350);
  //  }
  //}
  .form-content{
    padding:0;
    overflow:hidden;
    @include breakpoint(large){
      max-width:rem-calc(860);
    }
  }
  .tabs-panel{
    padding:0;
    .form-cont{
      padding:2rem 1.5rem;
      @include breakpoint(large){
        padding:3rem 2rem;
      }
    }
  }
  .main-content{
    @include breakpoint(small only){
      margin-top:0;
    }
  }
}

.update-output {
  display:none;
  text-align:center;
  margin-bottom:1.5rem;
  padding:2rem 1rem;
  overflow:hidden;
  background-color: $lighter-blue;
  &.update_True {
    display:block;
    &.success_True {
      background-color:$drk-green;
      p{
        color:white;
      }
    }
    &.success_False {
      background-color:rgba($alert-color, 0.1);
      p{
        color:$alert-color;
      }
    }
  }
}
//
//.update-section {
// width:100%;
//}

.darker-row {
  background-color: $lighter-blue;
  padding: rem-calc(40) rem-calc(25) rem-calc(40) rem-calc(25) !important;
  margin: 0 !important;
}

.subscription-status {
  font-size: rem-calc(22);
  color: $field-font-color;
}
#subscription_status_cont{
  .button{
    @include margin-center();
    width:100%;
    max-width:rem-calc(320);
    margin-bottom:0;
    @include breakpoint(medium){
      margin-right:0;
      margin-left:auto;
    }
    @include breakpoint(large){
      width:80%;
    }
  }
}

.light-gray-filler {
  background-color: rgba(255,255,255,0.56);
  min-height: rem-calc(318);
  margin-bottom: rem-calc(41);
}
.reveal{
  background-color:white;
  border:none;
  border-radius:rem-calc(8);
  .inner{
    padding:1.5rem 0;
    text-align:center;
    margin-top:2rem;
    margin-bottom:1.5rem;

    @include margin-center();
    @include breakpoint(medium){
      width:90%;
      padding:1.5rem;
    }
  }
  &.cancel-modal{
    .confirm-button-row{
      .cell:last-of-type{
        .button{
          max-width:none;
          width:100%;
        }
      }
    }
  }
  .confirm-button-row{
    margin-top:2rem;
    width:100%;
    @include margin-center();
    @include breakpoint(medium){
      width:90%;
    }
    .button{
      font-size:1.2rem;
      border:none;
      &:not(.cancel){
        border: none;
        background-color:$light-blue;
        color:white;
        text-transform:uppercase;
        border-radius:4rem;
        //padding:1rem 1.6rem;
        font-size:1.2rem;
        &:hover, &:focus, &:active{
          background-color:darken($primary-color, 20%);
          color:white;
        }
      }
    }
    .cell:first-of-type{
      .button{
        @include breakpoint(medium){
          width:80%;
          @include margin-center();
          max-width:rem-calc(350);
        }
      }
    }
    .cell:last-of-type{
      .button{
        @include breakpoint(medium){
          width:70%;
          @include margin-center();
          max-width:rem-calc(150);
        }
      }
    }
  }
  .modal-title, .modal-body{
    color:$drk-green;
  }
  .modal-body{
    opacity:0.8;
    margin-top:1rem;
  }
}
.subscription-status{
  color:$drk-green;
  &.inactive{
    color:$alert-color;
  }
}
#subscription_status_cont{
  .cell{
    &:first-of-type{
      @include breakpoint(small only){
        margin-bottom:2rem;
      }
    }
  }
}

