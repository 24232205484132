
#injection_login{
    .top-bar{
        .button.login{
            display:none;
        }
    }
}

.form-card{
    box-shadow: 0 2px 9px 0 rgba(black,0.13);
    border-radius: 8px;
    padding:2rem;
    background-color:white;
    max-width:rem-calc(860);
    @include margin-center();
}
.login-card {
    margin-top:2rem;
    .login-form-cont {
        //max-width:40rem;
        max-width:rem-calc(380);
        margin-left:auto;
        margin-right:auto;
        @include breakpoint(medium){
            margin-top:1rem;
            margin-bottom:1.3rem;
        }

        .forgot-pass-cont {
            text-align:right;
            a {
                color:$light-gray;
                padding-right:0.5rem;
                &:hover, &:focus {
                    color:gray;
                }
            }
        }
        > h1, > h2, > h3, > h4, > h5 {
            padding-bottom:1rem;
            margin-bottom:1rem;
            border-bottom:0.2rem solid lighten($light-blue,20%);
        }
        label {
            color:$font-light-blue;
        }
        .input-cont{
            &.error{
                label{
                    color:$alert-color;
                }
            }
        }
        //input[type=submit] {
        //    border-radius:2rem;
        //    margin-left:auto;
        //    margin-right:auto;
        //    display:block;
        //    padding:1rem;
        //    padding-left:3rem;
        //    padding-right:3rem;
        //    text-transform:uppercase;
        //    background-color:$light-blue;
        //    border:none;
        //    color:white;
        //    transition:0.5s ease;
        //    &:hover, &:focus {
        //        background-color:darken($light-blue,20%);
        //    }
        //}
        .sign-up-cont {
            text-align:center;
            color:$light-gray;
            a {
                text-decoration:underline;
                color:$font-light-blue;
                &:hover, &:focus {
                    color:darken($font-light-blue,20%);
                }
            }
        }
    }
}

a.disclaimer{
    text-decoration:underline;
    font-weight:$global-weight-bold;
    &:hover, &:focus, &:active{
        color:rgba($drk-green, 1);
    }
}
#forgot_pw_block{
    display:none;
    width:100%;
}
#reset_pass_error{
    display:none;
    width:100%;
    .error-message{
        &:before{
            content:'';
        }
    }
}


