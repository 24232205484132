
.button, button.button, a.button{
  border: 5px solid rgba(255,255,255,0.25);
  background-color:$light-blue;
  color:white;
  text-transform:uppercase;
  border-radius:4rem;
  padding:0.8rem 1.6rem;
  font-size:1.5rem;
  @include ease-all();
  font-weight:$global-weight-bold;

  &:hover, &:focus, &:active{
    background-color:white;
    color:$primary-color;
  }
  &:focus{
    outline:none;
  }
  &.login{
    width:100%;
    background-color: rgba($dark-gray,0.30);
    padding:0.6rem 2.3rem;
    font-size:1.2rem;
    border: rem-calc(5) solid rgba(white, 0.4);
    @include ease-all();
    &:hover, &:active, &:focus {
      color:$primary-color;
      background-color:white;
    }
  }
  &.submit{
    //background-color:$primary-color;
    //color:white;
    //@include ease-all();
    //&:hover, &:focus, &:active{
    //  background-color:darken($primary-color, 20%);
    //}
  }
  &.subtle{
    border: 3px solid rgba($primary-color,0.19);
    background-color:white;
    //border-radius: rem-calc(30);
    padding: 0.6rem 1rem;
    color:$primary-color;
    font-size:rem-calc(18);
    &:hover, &:focus, &:active{
      background-color:rgba($primary-color,0.19);
      border-color:transparent;
    }
  }
  &.cancel{
    color:$alert-color;
    border:none;
    background-color:rgba(black, 0.025);
    &:hover, &:focus, &:active{
      color:white;
      background-color:$alert-color;
    }
  }
  &.reg{
    border: none;
    background-color:$light-blue;
    color:white;
    text-transform:uppercase;
    border-radius:4rem;
    //padding:1rem 1.6rem;
    font-size:1.2rem;
    &:hover, &:focus, &:active{
      background-color:darken($primary-color, 20%);
      color:white;
    }
  }
}
//
//.update-btn{
//  display: inline-block;
//  background-color: #89D3DC;
//  text-align: center;
//  color: $white;
//  font-weight: 500;
//  font-size: rem-calc(18);
//  padding: rem-calc(10) rem-calc(35) rem-calc(10) rem-calc(35);
//  border-radius: rem-calc(30);
//  border:none;
//  @include ease-all();
//}
//
//.update-btn:hover {
//    color: rgba(255, 255, 255, 0.5);
//}

//#cancel-subscription-btn {
//  color: $primary-color;
//  font-size: rem-calc(18);
//  text-align: center;
//  background: transparent;
//  border: 5px solid rgba(137,211,220,0.19);
//  border-radius: rem-calc(30);
//  padding: rem-calc(10) rem-calc(35) rem-calc(10) rem-calc(35);
//  @include ease-all();
//  @include margin-center();
//  @include breakpoint(medium){
//    float: right;
//    margin-top: rem-calc(25);
//  }
//}