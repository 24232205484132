html,
body{
  overflow-x:hidden;
}

.main-content {
  margin-top: rem-calc(44);
}

.general-template{
  .main-content{
    margin-top: rem-calc(60);
  }
  &:not(.homepage){
    h1, h2, h3, h4, h5 {
        text-align:center;
        color:$font-light-blue;
        display:table;
        margin-left:auto;
        margin-right:auto;
        width:auto;
    }
  }
}
.account, .general-template{
  p{
    font-size:rem-calc(18);
    font-weight:$global-weight-bold;
    &:last-of-type{
      margin-bottom:0;
    }
  }
  .grid-padding-x, .grid-margin-x{
    .sidebar-col{
      @include breakpoint(small only){
        padding-left:0;
        padding-right:0;
        margin-left:0;
        margin-right:0;
      }
      &.left{
        padding-left:0;
        margin-left:0;
      }
      &.right{
        padding-right:0;
        margin-right:0;
      }
    }
  }
  .sidebar-col{
    .sidebar-inner{
      max-width:rem-calc(470);
    }
    &.left{
      .sidebar-inner{
        margin-left:0;
        margin-right:auto;
      }
    }
    &.right{
      .sidebar-inner{
        margin-right:0;
        margin-left:auto;
      }
    }
  }
}

.center{
  text-align: center;
}

.signup-complete-icon {
  img{
    height: rem-calc(138);
    width: rem-calc(138);
  }
}

.scroll-to-top {
  padding: 1rem;
  width:auto;
  max-width:100%;
  text-align:center;
  display:block;
  @include margin-center();
  font-weight:$global-weight-normal;
  .fa{
    margin-left:0.25rem;
    font-size:1rem;
  }
}
.error-page, .forgot-pass{
  background-color:$light-blue;
  .top-bar{
    .button{
      display:none;
    }
  }
  .main-content{
    margin-top:rem-calc(50);
    padding-bottom:1rem;
    @include breakpoint(medium){
      margin-top:rem-calc(100);
    }
    .error-message-block{
      max-width:rem-calc(800);
      @include margin-center();
      text-align:center;
      .error-title{
        color:$drk-green;
        margin-bottom:1.5rem;
        font-size:3rem;
      }
      .fa{
        color:$drk-green;
        font-size:rem-calc(90);
        @include margin-center();
        margin-bottom:1rem;
        @include breakpoint(medium){
          font-size:rem-calc(150);
        }
      }
      p{
        color:$drk-green;
        font-size:1.2rem;
      }
      .button{
        @include margin-center();
        max-width:rem-calc(300);
        margin-top:2rem;
      }
    }
  }
}
