.form-cont{
  .disclaimer{
    font-style:italic;
    color:rgba($drk-green, 0.8);
    font-weight:$global-weight-bold;
    text-align:center;
  }
  a.disclaimer{
    text-decoration:underline;
    text-align:center;
    display:block;
    width:auto;
    font-weight:$global-weight-bold;
    &:hover, &:focus, &:active{
      color:rgba($drk-green, 1);
    }
  }
  form{
    overflow:hidden;
    .form-section-title{
      padding:1rem;
      margin-top:1.5rem;
      margin-bottom:2rem;
      border-bottom:0.2rem solid lighten($light-blue,20%);
      width:auto;
      margin-left:auto;
      margin-right:auto;
      font-size:1.5rem;
      @include breakpoint(large){
        font-size:rem-calc(30);
      }
      font-weight:$global-weight-normal;
      &:first-of-type{
        margin-top:0;
      }
    }
    label {
      color:$font-light-blue;
      &.required {
        &:after {
          content:'*';
          margin-left:0.5rem;
        }
      }
    }
  }
  .grid-x{
    .cell:not(:first-of-type){
      @include breakpoint(medium){
        padding-left:map_get($grid-padding-gutters, small);
      }
    }
  }
  input[type=submit].button.submit, button.submit{
    border: none;
    background-color:$light-blue;
    color:white;
    text-transform:uppercase;
    border-radius:4rem;
    padding:1rem 1.6rem;
    font-size:1.2rem;
    display:block;
    width:100%;
    @include margin-center();
    max-width:rem-calc(300);
    @include ease-all();
    font-weight:$global-weight-bold;
    margin-top:2rem;
    margin-bottom:1rem;
    @include breakpoint(medium){
      width:80%;
    }
    &:hover, &:focus, &:active{
      background-color:darken($primary-color, 20%);
      color:white;
    }
    &:focus{
      outline:none;
    }
  }
  .checkbox-cont{
    overflow:hidden;
    display:flex;
    margin-bottom:1rem;
    //padding-top:0.5rem;
    label{
      max-width:80%;
      padding-top:0;
      margin-top:0;
    }
    input[type=checkbox], label{
      align-self:start;
    }
    input[type=checkbox]{
      margin-top:0.5rem;
    }
  }
  input, textarea{
    @include ease-all();
    border-radius: 3px;
  }
}
.form-col{
  @include breakpoint(small only){
    margin-bottom:3rem;
  }
}

.form-content {
  border-radius: rem-calc(8);
  box-shadow: 0 rem-calc(2) rem-calc(9) 0 rgba(0,0,0,0.13);
  background-color: white;
  padding:2rem 1rem;
  @include breakpoint(large){
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
    padding-top: rem-calc(61);
    padding-bottom: rem-calc(61);
  }

}

.entry-page.signup-page{
  .form-content, .app-store-container{
    @include breakpoint(large){
      max-width:rem-calc(860);
      margin-right:0;
      margin-left:auto;
    }
  }
}

.form-title {
  color: $primary-color;
  font-size: rem-calc(30);
  text-align: center;
}

.form-sub-text {
  font-size: rem-calc(20);
  text-align: center;
  color: #C3C3C3;
}

.form-divider {
  width: 22%;
  height: rem-calc(4);
  background-color: #F1FAFB;
  border: none;
}

.error-message, .info-message{
  border-radius:6px;
  padding:1rem;
  margin-top:1.5rem;
  text-align:center;
  @include ease-all();
  &:empty {
    display:none;
  }
}
.error-message{
  //display:none;
  background-color:rgba($alert-color, 0.2);
  color: $alert-color;
  &:before {
    content:'Error: ';
  }
}
.info-message{
  background-color:rgba($drk-green, 0.1);
  color:$drk-green;
  font-style: italic;
}
#current_card_cont{
  display:block;
}
#update_card_form{
  display:none;
}
.fake-input{
  color: $drk-green;
  padding:0.25rem 1rem 1rem 0;
  margin-bottom:0.5rem;
 }
#show_update_card_block{
  display:inline-block;
  width:auto;
  max-width:rem-calc(150);
  margin-right:auto;
  margin-left:0;
  //text-align: right;
  margin-top:1rem;
  margin-bottom:1rem;
  padding:0.25rem 1rem;
  border-radius:2rem;
  text-align:center;
  font-weight:$global-weight-normal;
  background-color:rgba(black, 0.025);
  &:hover, &:focus, &:active{
    background-color:$drk-green;
    color:white;
  }
  &.cancel{
    color:$alert-color;
    margin-left:0;
    margin-right:auto;
    &:hover, &:focus, &:active{
      background-color:$alert-color;
      color:white;
    }
  }
}
.input-cont{
  &.error{
    label{
      color:$alert-color;
    }
    input, textarea{
      border:2px solid $alert-color;
    }
  }
}

.consent-form {
  overflow: auto;
  .mce-container {
    margin-bottom: 1rem;
  }
  .mce-panel {
    width: auto !important;
    box-shadow: none !important;
    border: 2px solid $lightest-blue !important;
    background-color:#fcfcfc !important;

    &.mce-toolbar-grp {
      border-left: none !important;
      border-right: none !important;
    }

    &.mce-edit-area {
      border: none !important;
    }

    &.mce-statusbar {
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
    }
  }
  .mce-btn-group:not(:first-child) {
    border-left: none !important;
  }
  .mce-btn {
    border-color: rgba($light-blue, 0.02) !important;
    &:hover, &:active, &:focus {
      border-color: rgba($light-blue, 0.2) !important;
    }
    button {
      background-color: rgba($light-blue, 0.02);
    }
  }
  .mce-menubar {
    border: none !important;
  }
  .mce-top-part::before {
    content: none !important;
  }
  .consent-save-button {
    margin: 0 auto;
    display: block;
  }
}
