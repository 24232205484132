.signup-complete-message {
    display:none;
    &.success_True {
        display:block;
        .form-title{
            margin-top:1.5rem;
        }
    }
}
.signup-form-cont {
    &.success_True {
        display:none;
    }
    form {
        input[type=submit], button {
            &.hidden {
                display:none;
            }
        }
        .checkbox-cont {
            label {
                a {
                    font-weight:$global-weight-bold;
                    border-bottom:1px solid transparent;
                    &:hover, &:focus {
                        border-bottom:1px solid $primary-color;
                    }
                }
            }
        }
    }
    .discount-code-cell {
        position:relative;
        display:inline-block;

        .discount_code {
            display:inline-block;
            border-radius:0.5rem;
            position:relative;
        }
        .apply-discount-code {
            display:inline-block;
            padding:0.5rem;
            padding-left:1rem;
            padding-right:1rem;
            border-radius:0.5rem;
            position:absolute;
            top:1.8rem;
            bottom:0rem;
            right:0.125rem;
            display:flex;
            align-items:center;
            height:3rem;
            margin-top:0.125rem;
            margin-bottom:0.125rem;
            background-color:white;
            text-transform:uppercase;
        }
        .discount_info {
            padding:1rem;
            border-top:0.2rem solid $light-blue;
            &:empty {
                border:none;
            }
            &.success {
                border-top:0.2rem solid #96e378;
            }
        }
    }
    .stripe-container {
        input[type=submit], button {
            &.hidden {
                display:none;
            }
            border: none;
            background-color:$light-blue;
            color:white;
            text-transform:uppercase;
            border-radius:4rem;
            padding:1rem 1.6rem;
            font-size:1.2rem;
            display:block;
            @include margin-center();
            width:80%;
            max-width:rem-calc(300);
            @include ease-all();
            font-weight:$global-weight-bold;
            margin-top:2rem;
            margin-bottom:1rem;
            &:hover, &:focus, &:active{
                background-color:darken($primary-color, 20%);
                color:white;
            }
            &:focus{
                outline:none;
            }
        }
    }
}
.entry-page{
    .info-card-col{
        padding-right:0;
        @include breakpoint(medium only){
            padding-left:0;
            margin-left:0;
        }
    }
    .info-container {
        .info-card-title{
            text-align:center;
            @include breakpoint(medium){
                text-align:left;
            }
            margin-bottom:2rem;
        }
        .info-card {
            color:$font-light-blue;
            padding:1.7rem 1rem 2rem 1rem;
            background-color: rgba(white,0.88);
        }
        .inner-card-title{
            margin-bottom:1.5rem;
        }
    }
    ul.feature-list{
        list-style: none;
        margin:0;
        width:100%;
        @extend %clearfix;
        li{
            display:block;
            width:100%;
            &:not(:last-of-type){
                margin-bottom:1.2rem;
            }
            .icon-cont, .details{
                float:left;
                @include breakpoint(medium only){
                    float:none;
                    width:100% !important;
                }
            }
            .icon-cont{
                width:30%;
                img{
                    @include margin-center();
                    max-width:rem-calc(56);
                    @include breakpoint(medium only){
                        margin-left:0;
                        margin-right:auto;
                        margin-bottom:0.75rem;
                        max-width:rem-calc(46);
                    }
                }
            }
            .details{
                width:70%;
                text-align:left;
                color:$drk-green;
                p{

                }
            }
        }
    }
}

.trial-disclaimer {
  color:$font-light-blue;
  font-size:0.85rem;
  text-align:center;
  padding-top:1rem;
  padding-bottom:1rem;
}


.grid-x.cost-items {
  margin-top:2rem;
  .cell {
    &:nth-child(2n+1) {
      color:$light-blue;
    }
    &:nth-child(2n) {
      text-align:right;
    }
  }
}

.cost-summary {
  padding-top:1.5rem;
  padding-bottom:1.5rem;
  border-top:0.2rem solid $light-blue;
  margin-top:2rem;
  label {
    text-transform:uppercase;
  }
  .amount {
    text-align:right;
    font-weight:500;
    font-size:1.5rem;
    color:teal;
    &:before {
      content:'$';
    }
    &:after {
      content:'/mo';
    }
    &:empty {
      &:before {
        content:'';
      }
      &:after {
        content:'FREE';
      }
    }
  }
}

.referral-name {
    margin-bottom:2rem;
}
