header{
  position: relative;
}

.general-template {
  header {
    background: white url(../img/imagery/hero_home.jpg) no-repeat top center;
    background-size:cover;
    min-height: rem-calc(266);
  }
  &.homepage{
    header{
      text-align:left;
      color:white;
      @include breakpoint(small only){
        padding-bottom:3rem;
      }
      @include breakpoint(medium){
        min-height:rem-calc(500);
      }
      @include breakpoint(large){
        min-height:rem-calc(900);
      }
      &:before{
        display:block;
        content:' ';
        position:absolute;
        left:0;
        top:0;
        bottom:0;
        right:auto;

        height:100%;
        background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
        z-index:0;
        width:100%;
        @include breakpoint(medium){
          width:60%;
        }
      }
    }
  }
}
.homepage{
  .header-content{
    position:relative;
    z-index:1;
    .grid-x{
      margin-left:0;
      margin-right:0;
      @include breakpoint(medium){
        padding-left:map_get($grid-padding-gutters, medium) /2;
        padding-right:map_get($grid-padding-gutters, medium) /2;
      }
    }
    .cell{
      margin-top:4rem;
      @include breakpoint(large){
        margin-bottom:8rem;
      }
      &>.inner{
        margin-bottom:3rem;
        @include breakpoint(medium){
          margin-bottom:8rem;
        }
        @include breakpoint(large){
          margin-top:7rem;
        }
      }
    }
    .hero-title{
      color:white;
      margin-bottom:3rem;
      text-align:center;
      @include breakpoint(medium){
        text-align:left;
      }

    }
    .button{
      display:block;
      @include margin-center();
      width:90%;
      @include breakpoint(medium){
        margin-left:0;
        margin-right:auto;
        max-width:rem-calc(300);
      }
    }
  }
}

.header-background {
  width:100%;
  height: inherit;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.header-bottom {
  position: absolute;
  top:auto;
  bottom: 0;
  @include breakpoint(medium) {
    //position:relative;
    //bottom:0;
    //top:0;
  }
}

