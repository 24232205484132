.homepage{
  .content-wrapper{
    background-color:white;
    .inj-section:first-of-type{
      padding-top:rem-calc(68);
      padding-bottom:0;

      @include breakpoint(xlarge) {
        padding-top:rem-calc(100);
      }
    }
  }
}
.hero-video {
  padding: 2rem 10% 0;

  @include breakpoint(medium) {
    padding: 2rem 20% 0;
  }

  @include breakpoint(large) {
    padding: 2rem 0 0 2rem;
    margin-top: -12rem;
  }

  @include breakpoint(xlarge) {
    padding: 0 0 0 2rem;
  }

  iframe {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 17.5rem;
    border: 5px solid #FFFFFF;
    border-radius: 4px;
    background-color: #4A4A4A;
    box-shadow: 0 2px 17px 0 #B0B0B0;

    @include breakpoint(large) {
      height: 25rem;
    }
  }
}
.inj-section{
  padding-top:4rem;
  padding-bottom:4.2rem;
  &>.grid-x{
    padding-left:map_get($grid-padding-gutters, small)/2;
    padding-right:map_get($grid-padding-gutters, small)/2;
  }
}
#injection_product_info{
  &>.grid-x{

  }
}
.product-info-grid{
  margin-bottom:3rem;
  max-width:$global-width;
  margin-left:auto;
  margin-right:auto;
  .icon-block{
    text-align:center;
    margin-bottom:1.5rem;
    @include breakpoint(large){
      margin-bottom:0;
    }
    &>.inner{
      max-width:rem-calc(290);
      @include margin-center();
    }
    .icon-cont{
      height:rem-calc(100);
      display: flex;
      align-items:center;
      justify-content: center;
      margin-bottom:1rem;
      img{
        @include margin-center();
        max-height:rem-calc(70);
        @include breakpoint(medium){
          max-height:rem-calc(90);
        }
      }
    }
    .details{
      font-size:rem-calc(18);
      color:$dark-gray;
      span{
        color:$primary-color;
      }
    }
  }
}
a.floating-action-button{
  width:85%;
  background-color:white;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.13);
  @include margin-center();
  @include ease-all();
  &.xl{
    //max-width:rem-calc(500);
    border-radius:rem-calc(8);
    padding:1.7rem  2.5rem 1.5rem 2.5rem;
    @include breakpoint(medium){
      border-radius:rem-calc(100);
    }
  }
  .subscribe-col, .price-col{
    float:none;
    width:100%;
    padding-left:map_get($grid-padding-gutters, small)/2;
    padding-right:map_get($grid-padding-gutters, small)/2;
    @include breakpoint(medium){
      float:left;
      width:50%;
      display:flex;
      justify-content: center;
      &>.inner{
        width:100%;
        height:auto;
      }
    }
  }
  .subscribe-col{
    @include breakpoint(small only){
      margin-bottom:1rem;
    }
    &>*{
      text-align:center;
      @include breakpoint(medium){
        text-align:right;
      }
    }
    .callout-title{
      font-weight:$global-weight-normal;
      color:$dark-gray;
      font-size:rem-calc(32.15);
      //text-align:right;
      margin-bottom:0.8rem;
    }
    .fake-link{
      color:$primary-color;
      //text-align: right;
      font-size:1rem;
      .fa{
        margin-left:0.5rem;
        font-size:1rem;
      }
    }
  }
  .price-col{
    text-align:right;
    .price{
      //font-weight:$global-weight-normal;
      font-size:rem-calc(80);
      line-height: 1;
      margin-bottom:0;
    }
    sup, sub{
      font-weight:$global-weight-light;
      font-size:rem-calc(40);
    }
    sub{
      bottom:unset;
    }
  }
  &:hover, &:focus, &:active{
    //padding-left:3rem;
    //padding-right:3rem;
    background-color:$primary-color;
    width:90%;
    //max-width:none;
    .subscribe-col, .price-col{
      .price, .fake-link, .callout-title, .fa{
        color:white;
      }
      .fake-link, sup,sub{
        opacity:0.6;
      }
    }
  }
}
.signup-row{
  position:relative;
  z-index:2;
  .floating-action-button{
    position:relative;
    z-index:3;
  }
}
.download-row{
  background-color:$lightest-blue;
  padding-top:6rem;
  padding-bottom:6rem;
  position:relative;
  z-index:1;
  &:before{
    display:block;
    content:' ';
    background-color:$lightest-blue;
    height:rem-calc(75);
    width:100%;
    top:0;
    right:0;
    left:0;
    margin-top:rem-calc(-60);
    position:absolute;
    z-index:0;
  }
}
.download-block{
  text-align:center;
  p.disclaimer{
    color:$primary-color;
    font-size:1.1rem;
    font-weight:$global-weight-normal;
    margin-bottom:1rem;
    display:block;
  }
}
a.download-btn{
  display:block;
  width:auto;
  @include margin-center();
  max-width:rem-calc(210);
  border:3px solid transparent;
  @include ease-all();
  overflow:hidden;
  border-radius:8px;
  img{
    @include ease-all();
    height:auto;
    max-width:100%;
    max-height:100%;
    display:block;
    @include margin-center();
    opacity:0.8;
  }
  &:hover, &:focus, &:active{
    border-color:$primary-color;
    img{
      opacity:1;
    }
  }
}
.tutorial-row{
  background-color:$lightest-blue;
  padding-top:0rem;
  padding-bottom:6rem;
  position:relative;
  text-align:center;
  p.tutorial-info {
    color:$dark-gray;
    margin-bottom:2rem;
  }
  .floating-action-button {
    max-width:rem-calc(400);
    &:hover, &:focus, &:active {
      max-width:rem-calc(420);
      color:$white;
    }
  }
}
#injection_footer_callout{
  background: $drk-green url(../img/imagery/faces_section_bg.jpg) no-repeat center center;
  background-size:cover;
}
.callout-section{
  padding-top:3rem;
  padding-bottom:2rem;
  display:flex;
  justify-content: center;
  position:relative;
  min-height:rem-calc(250);
  @include breakpoint(large){
    min-height:rem-calc(500);
  }
  &:before{
    display:block;
    content:' ';
    background-color: rgba(black,0.53);
    height:100%;
    width:100%;
    top:0;
    right:0;
    left:0;
    position:absolute;
    z-index:0;
  }
  &>.grid-x{
    width:100%;
    align-self:center;
  }
  &>.grid-container {
    z-index:1;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .callout-text{
    position:relative;
    z-index:2;
//    text-align:center;
    color:$light-blue;
    padding:1rem;
    .text{
      font-weight:$global-weight-light;
    }
    .get-in-touch {
      margin-top:1rem;
      color:white;
    }
  }
  .vcenter-cell {
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .contact-form-cont {
    border-radius:4px;
    box-shadow: 0 2px 9px 0 rgba(0,0,0,0.13);
    background-color:white;
    padding:rem-calc(33);
    label {
      color:#808080;
    }
    textarea {
      height:rem-calc(100);
    }
    input[type=submit], button {
      &.hidden {
        display:none;
      }
      border: none;
      background-color:$light-blue;
      color:white;
      text-transform:uppercase;
      border-radius:4rem;
      padding:1rem 1.6rem;
      font-size:1.2rem;
      display:block;
      @include margin-center();
      width:80%;
      max-width:rem-calc(300);
      @include ease-all();
      font-weight:$global-weight-bold;
      margin-top:2rem;
      margin-bottom:1rem;
      &:hover, &:focus, &:active{
        background-color:darken($primary-color, 20%);
        color:white;
      }
      &:focus{
        outline:none;
      }
    }
    .contact-success {
      color:$primary-color;
    }
    .error-message {
      margin-top:0rem;
      margin-bottom:1rem;
    }
  }
}
.site-footer{
  position:relative;
  padding:1.5rem;
  margin-left:0;
  margin-right:0;
  &.grid-x{
    //padding-bottom:1.5rem;
    padding-top:1rem;
    .cell{
      display:flex;
      flex-direction:column;
      @include breakpoint(small only){
        text-align:center;
        &:first-of-type{
          margin-bottom:1rem;
        }
      }
    }
  }
  .legal-cont{
    margin:0;
    margin-top:auto;
    list-style: none;
    @include breakpoint(medium){
      text-align:right;
    }
    li{
      display:inline-block;
      &:not(:first-of-type){
        @include breakpoint(medium){
          margin-left:1rem;
        }
      }
      a{
        @include breakpoint(small only){
          text-align: center;
        }
        font-size:rem-calc(18);
      }
    }
  }
}

