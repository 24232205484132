.tutorial-heading-row {
     margin-bottom:2rem;
    .tutorial-heading {
        text-align:left !important;
        width:100% !important;
    }
    p {
        color:rgba(39,55,56,0.5);
    }
}

.tutorial-reveal-link {
    .card {
        border-radius:4px;
        transition:0.5s ease;
        border:none;
        .thumbnail {
            transition:0.5s ease;
            background-size:cover;
            background-position:center center;
            background-repeat:no-repeat;
            min-height:10rem;
            border-radius:4px;
            margin:0rem;
            border:none;
            position:relative;
            .video-icon {
                position:absolute;
                left:rem-calc(10);
                bottom:rem-calc(10);
                color:rgba(0,0,0,0.74);
                transition:0.5s ease;
                font-size:rem-calc(40);
            }
        }
        .card-section {
            padding:1.5rem;
            transition:0.5s ease;
        }
    }
    &:hover, &:focus, &:active {
        .card {
            box-shadow: 0 2px 9px 0 rgba(0,0,0,0.13);
            .thumbnail {
                border-radius:4px 4px 0px 0px;
                .video-icon {
                    color:rgba(255,255,255,1);
                }
            }
            .card-section {
                color:#548A91;
            }
        }
    }
}

.reveal.tutorial-reveal {
    iframe {
        margin-top:2rem;
        min-height:50vh;
        width:100%;
    }
}

