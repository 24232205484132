.form-cont.organization-form-cont {
  color:$light-blue;
  line-height:1.5;
  em {
    font-style:normal;
    font-weight:bold;
  }
  h5 {
    line-height:1.5;
    em {
      text-transform:uppercase;
    }
  }
  .org-signup-btn {
    float:right;
  }
  ul {
    li {
      padding-top:0.25rem;
      padding-bottom:0.25rem;
    }
  }
}

.organization, .product {
  .account-type {
    text-transform:uppercase;
    font-size:1.25rem;
    color:#548A91;
  }
  .add-user-btn, .add-product-btn {
    float:right;
  }
  ul.org-user-list, ul.product-list {
    list-style-type:none;
    margin-left:0rem;
    background-color:#fcfcfc;
    li {
      padding:1.5rem;
      border: 2px solid #F1FAFB;
      position:relative;
      .user-name {
        font-size:1.25rem;
      }
      .edit-column {
        text-align:right;
        .access-level {
          display:inline-block;
          margin-right:1.5rem;
          text-transform:uppercase;
          border-radius:2.4px;
          background-color: #548A91;
          padding:0.5rem;
          padding-left:1.5rem;
          padding-right:1.5rem;
          color:#548A91;
        }
        .user-edit-btn, .product-edit-btn {
          display:inline-block;
        }
      }
      .enable-link {
        display:none;
        position:absolute;
        top:0rem;
        bottom:0rem;
        left:0rem;
        right:0rem;
        justify-content:center;
        align-items:center;

        background-color:$light-blue;
        color:white;
        text-transform:uppercase;
        opacity:0.6;
        &:hover, &:focus {
          opacity:1;
        }
      }
      &.access-owner {
        background-color:#fcfcfc;
        color:#273738;
        .edit-column {
          .access-level {
            background-color: #F6F6F6;
            &:before {
              content:'Owner';
            }
          }
        }
      }
      &.access-admin_access {
        .edit-column {
          .access-level {
            color:$white;
            &:before {
              content:'Admin';
            }
          }
        }
      }
      &.access-full_access {
        .edit-column {
          .access-level {
            background-color:$white;
            border: 1px solid #548A91;
            &:before {
              content:'Full Access';
            }
          }
        }
      }
      &.access-patient_access {
        .edit-column {
          .access-level {
            background-color: #F1FAFB;
            &:before {
              content:'Patient Access';
            }
          }
        }
      }
      &.disabled {
        .enable-link {
          display:flex;
        }
      }
    }
  }
}
.new-user-reveal, .new-product-reveal {
  width:800px;
  padding-top:2rem;
  padding-bottom:2rem;
  button.button.complete-form, a.close-form-reveal {
    display:block;
    margin-left:auto;
    margin-right:auto;
  }
  a.close-form-reveal {
    text-transform:uppercase;
    padding:1rem;
    text-align:center;
    border-radius:4rem;
    display:table;
    &:hover, &:focus {
      background-color:#70b8c2;
      color:white;
    }
  }
  .user-form, .product-form {
    > .grid-x {
      @include breakpoint(medium) {
        .cell:nth-child(2n+1) {
          padding-left:0rem;
        }
      }
      .access_info {
        padding:1rem;
        border-top:0.2rem solid $light-blue;
        &:empty {
          border:none;
        }
      }
      .cell.discount-code-cell {
        display:none;
      }
    }
    .hidden {
      display:none;
    }
    //changes to allow discount code entry
    &.with-discount-code {
      > .grid-x {
        .discount_code {
          display:inline-block;
          border-radius:0.5rem;
          position:relative;
        }
        .apply-discount-code {
          display:inline-block;
          padding:0.5rem;
          padding-left:1rem;
          padding-right:1rem;
          border-radius:0.5rem;
          position:absolute;
          top:1.8rem;
          bottom:0rem;
          right:0.125rem;
          display:flex;
          align-items:center;
          height:3rem;
          margin-top:0.125rem;
          margin-bottom:0.125rem;
          background-color:white;
          text-transform:uppercase;
        }
        .discount_info {
          padding:1rem;
          border-top:0.2rem solid $light-blue;
          &:empty {
            border:none;
          }
          &.success {
            border-top:0.2rem solid #96e378;
          }
        }

        .cell {
          &.email-cell {
            width:100%;
          }
          &.user-type-cell {
            padding-left:0rem;
          }
          &.discount-code-cell {
            padding-left:20px;
            position:relative;
            display:inline-block;
          }
        }
      }
    }
  }
  .product-form {
    .grid-x > .cell {
      padding-left:0rem;
      label.for-checkbox {
        display:inline-block;
      }
      input[type=checkbox] {
        display:inline-block;
      }
    }
  }
  .grid-x.cost-items {
    margin-top:2rem;
    .cell {
      &:nth-child(2n+1) {
        color:$light-blue;
      }
      &:nth-child(2n) {
        text-align:right;
      }
    }
  }
  .cost-summary {
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    border-top:0.2rem solid $light-blue;
    margin-top:2rem;
    label {
      text-transform:uppercase;
    }
    .amount {
      text-align:right;
      font-weight:500;
      font-size:1.5rem;
      color:teal;
      &:before {
        content:'$';
      }
      &:after {
        content:'/mo';
      }
      &:empty {
        &:before {
          content:'';
        }
        &:after {
          content:'FREE';
        }
      }
    }
  }
  .disable-user-link, .disable-product-link {
    display:none;
    text-transform:uppercase;
    color:#E36B6B;
    background-color:#F6F6F6;
    position:absolute;
    bottom:0rem;
    left:0rem;
    padding:1.5rem;
    margin:0.5rem;
    &:hover, &:focus {
      background-color:#E36B6B;
      color:#F6F6F6;
    }
  }
  &.edit-user-reveal, &.edit-product-reveal {
    .disable-user-link, .disable-product-link {
      display:block;
    }
  }
}

.consent-col {
  padding-left: 0 !important;
  margin-top: 0.5rem;
  .checkbox-grid {
    margin-bottom:1rem;
    input[type=checkbox], label {
      display:inline;
      margin-bottom:0rem;
    }
    input[type=checkbox]:checked ~ label {
      font-weight:bold;
    }
  }
}
