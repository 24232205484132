#site_nav_bar{
  a.nav-logo{
    padding: 0;
    @include ease-all();
    img {
      display:block;
      max-width:rem-calc(60);
      @include breakpoint(medium){
        width: rem-calc(88);
      }
    }
    &:hover, &:active, &:focus{
      opacity:0.7;
    }
  }
  ul.button-bar{
    list-style: none;
    margin:0;
    text-align: right;
    a.button.login{
      width:100%;
      max-width:rem-calc(200);
      margin-right:0;
      margin-left:auto;
      margin-bottom:0;
      @include breakpoint(small only){
        padding:0.5rem 1rem;
      }
    }

    li{
      display:inline-block;
    }
  }
  &.top-bar{
    width:100%;
    position:relative;
    z-index:100;
    @extend %clearfix;
    background: transparent;
    padding-right:map_get($grid-padding-gutters, small)/2;
    padding-left:map_get($grid-padding-gutters, small)/2;
    @include breakpoint(large){
      padding-right:map_get($grid-padding-gutters, small);
      padding-left:map_get($grid-padding-gutters, small);
    }
    & *:not(.button){
      background:transparent;
    }
    .top-bar-right, .top-bar-left{
      width:50%;
      flex: 0 0 50%;
      display:block;
      padding:0;
      margin:0;
      max-width:50%;
    }
    ul.button-bar{

    }
    //.top-bar-right{
    //  float:right;
    //}
    //.top-bar-left{
    //  float:left;
    //}
  }
}

.general-template{


}


.menu{
  //margin-left: rem-calc(40) !important;
  //margin-right: rem-calc(40) !important;
}



.nav-logo{
  @include ease-all();
  img{
    width: rem-calc(56);
    height: auto;
  }
}

.account{
  #site_nav_bar.top-bar {
    background-color: $primary-color;
  }
}