.account{
  .tabs-title>a{
    padding:1.2rem 1.5rem;
    @include breakpoint(large){
      padding:1.75rem 2.5rem;
    }
  }

}
.tabs{
  &.vertical{
    background-color: rgba(255,255,255,0.56);
    @include breakpoint(small only){
      margin-bottom:1.5rem;
    }
    @include breakpoint(medium){
      min-height:rem-calc(400);
    }
    .tabs-title{
      &>a{
        background-color:$white;
      }
    }
  }
}
.tabs-content{
  margin-bottom:3rem;
  @include breakpoint(medium){
    margin-bottom:rem-calc(150);
  }
  @include ease-all();
  &.form-content{
    //padding:2rem 1.5rem;
  }
}
.tabs-panel{
  @include fadein(0.8s, white, white, ease-in);
  @include ease-all();
}

.tabs-title.is-active {
  border-left: $primary-color solid 4px;
  @include breakpoint(medium) {
    border-left: none;
    border-right: rem-calc(4) solid !important;
    border-color: #89D3DC !important;
  }
}